import React from 'react';
import { MultiLang } from '../../../config';
import Functions from '../../../functions';
import { ItemPresentation } from '../../lib/ItemUtil';
import DetailBase from '../lib/DetailBase';
import ItemTypeField from '../lib/field';
import PresentationUtil from './PresentationUtil';

const societyType = (lang: MultiLang, type: string): string => {
    switch (type) {
        case '国内':
            return Functions.mlang('[en]Domestic[/en][ja]国内[/ja]', lang);
        case '国際':
            return Functions.mlang('[en]International[/en][ja]国際[/ja]', lang);
    }
    return '';
}

const publishType = (lang: MultiLang, type: string): string => {
    switch (type) {
        case '学会特別講演（特別講演、基調講演、教育講演等）':
            return Functions.mlang('[en]Special speech (e.g. Keynote, Lecture)[/en][ja]学会特別講演（特別講演、基調講演、教育講演等）[/ja]', lang);
        case 'シンポジウム講演':
            return Functions.mlang('[en]Symposium speech[/en][ja]シンポジウム講演[/ja]', lang);
        case '一般発表（口頭発表）':
            return Functions.mlang('[en]General (Oral)[/en][ja]一般発表（口頭発表）[/ja]', lang);
        case '一般発表（ポスター発表）':
            return Functions.mlang('[en]General (Poster)[/en][ja]一般発表（ポスター発表）[/ja]', lang);
        case 'その他':
            return Functions.mlang('[en]Other[/en][ja]その他[/ja]', lang);
    }
    return '';
}

class PresentationDetail extends DetailBase {

    getFields() {
        const { lang } = this.props;
        const item = this.props.item as ItemPresentation;
        return [
            { label: 'ID', value: item.doi },
            { label: '[en]Language[/en][ja]言語[/ja]', value: <ItemTypeField.Language lang={lang} itemLang={item.lang} /> },
            { label: '[en]Society Type[/en][ja]学会タイプ[/ja]', value: societyType(lang, item.society_type) }, // CBSN extended
            { label: '[en]Publication Type[/en][ja]発表タイプ[/ja]', value: publishType(lang, item.publish_type) }, // CBSN extended
            { label: '[en]Meeting Name[/en][ja]発表した会合の名称[/ja]', value: Functions.mlang(item.meeting_name, lang) }, // CBSN extended
            { label: '[en]City Name[/en][ja]発表都市名[/ja]', value: Functions.mlang(item.city_name, lang) }, // CBSN extended
            { label: '[en]Title[/en][ja]タイトル[/ja]', value: Functions.mlang(item.title, lang) },
            { label: '[en]Free Keywords[/en][ja]フリーキーワード[/ja]', value: <ItemTypeField.FreeKeyword lang={lang} keyword={item.keyword} /> },
            { label: '[en]Description[/en][ja]概要[/ja]', value: <ItemTypeField.Description lang={lang} description={item.description} /> },
            { label: '[en]Postscript[/en][ja]追記[/ja]', value: Functions.mlang(item.postscript, lang) }, // CBSN extended
            { label: '[en]Date[/en][ja]日付[/ja]', value: <ItemTypeField.PublicationDate lang={lang} year={item.publication_year} month={item.publication_month} mday={item.publication_mday} /> },
            { label: '[en]Last Modified Date[/en][ja]最終更新日[/ja]', value: <ItemTypeField.DateTime lang={lang} date={item.last_update_date} /> },
            { label: '[en]Created Date[/en][ja]作成日[/ja]', value: <ItemTypeField.DateTime lang={lang} date={item.creation_date} /> },
            { label: '[en]Contributor[/en][ja]登録者[/ja]', value: <ItemTypeField.Contributer lang={lang} uname={item.uname} name={item.name} /> },
            { label: '[en]Item Type[/en][ja]アイテムタイプ[/ja]', value: item.item_type_display_name },
            { label: '[en]Change Log(History)[/en][ja]変更履歴[/ja]', value: <ItemTypeField.ChangeLog lang={lang} changelog={item.changelog} /> },
            { label: '[en]Presentation Type[/en][ja]ファイル形式[/ja]', value: <PresentationUtil.PresentationType lang={lang} type={item.presentation_type} /> },
            { label: '[en]Creator[/en][ja]作成者[/ja]', value: <ItemTypeField.Author lang={lang} author={item.creator} /> },
            { label: '[en]Preview[/en][ja]プレビュー[/ja]', value: <ItemTypeField.Preview lang={lang} file={item.file} /> },
            { label: '[en]Presentation File[/en][ja]発表資料[/ja]', value: <ItemTypeField.ItemFile lang={lang} file={item.file} type="presentation_file" rights={item.rights} useCc={item.use_cc} ccCommercialUse={item.cc_commercial_use} ccModification={item.cc_modification} downloadLimit={item.attachment_dl_limit} /> },
            { label: 'Readme', value: <ItemTypeField.Readme lang={lang} readme={item.readme} /> },
            { label: 'Rights', value: <ItemTypeField.Rights lang={lang} rights={item.rights} useCc={item.use_cc} ccCommercialUse={item.cc_commercial_use} ccModification={item.cc_modification} /> },
            { label: 'Index', value: <ItemTypeField.ItemIndex lang={lang} index={item.index} /> },
            { label: '[en]Related to[/en][ja]関連アイテム[/ja]', value: <ItemTypeField.RelatedTo lang={lang} relatedTo={item.related_to} /> },
        ];
    }
}

export default PresentationDetail;