import React from 'react';
import { Helmet } from 'react-helmet';
import Config, { MultiLang } from '../config';
import Functions from '../functions';
import Announce from './blocks/Announce';

interface Props {
    lang: MultiLang;
}

const About = (props: Props) => {
    const { lang } = props;
    const title = Functions.mlang('[en]Announce[/en][ja]ご案内[/ja]', lang);
    return (
        <div>
            <Helmet>
                <title>{title} - {Functions.mlang(Config.SITE_TITLE, lang)}</title>
            </Helmet>
            <h1>{title}</h1>
            <Announce lang={props.lang} />
        </div>
    );
}

export default About;