import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MultiLang } from '../config';
import Rankings from '../database/blocks/Rankings';
import RecentContents from '../database/blocks/RecentContents';
import DatabaseTop from '../database/DatabaseTop';
import Functions from '../functions';
import Announce from '../custom/blocks/Announce';
import MainContent from './MainContent';

interface Props {
    lang: MultiLang;
}

const CenterBlocks = (props: Props) => {
    const { lang } = props;
    const titles = {
        announce: '[en]Announce[/en][ja]ご案内[/ja]',
        databaseTop: '[en]Item Types[/en][ja]アイテムタイプ一覧[/ja]',
        ranking: '[en]Ranking[/en][ja]ランキング[/ja]',
        recentContents: '[en]Recent Contents[/en][ja]新着コンテンツ[/ja]',
    };
    return (
        <div className="block">
            <div className="centerCcolumn">
                <div className="block">
                    <h2 className="blockTitle">{Functions.mlang(titles.announce, lang)}</h2>
                    <div className="blockContent">
                        <Announce lang={lang} />
                    </div>
                </div>
                <div className="block">
                    <h2 className="blockTitle">{Functions.mlang(titles.databaseTop, lang)}</h2>
                    <div className="blockContent">
                        <DatabaseTop lang={lang} />
                    </div>
                </div>
            </div>
            <div className="clearfix">
                <div className="centerLcolumn">
                    <div className="block">
                        <h2 className="blockTitle">{Functions.mlang(titles.ranking, lang)}</h2>
                        <div className="blockContent">
                            <Rankings lang={lang} />
                        </div>
                    </div>
                </div>
                <div className="centerRcolumn">
                    <div className="block">
                        <h2 className="blockTitle">{Functions.mlang(titles.recentContents, lang)}</h2>
                        <div className="blockContent">
                            <RecentContents lang={lang} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const CenterColumn = (props: Props) => {
    const { lang } = props;
    return (
        <div className="centercolumn">
            <Switch>
                <Route exact path="/" render={() => <CenterBlocks lang={lang} />} />
            </Switch>
            <div className="block">
                <MainContent lang={lang} />
            </div>
        </div>
    );
}

export default CenterColumn;