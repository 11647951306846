import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Config, { MultiLang } from '../config';
import D3Forum from '../d3forum/D3Forum';
import D3ForumXoopsPathRedirect from '../d3forum/D3ForumXoopsPathRedirect';
import Database from '../database/Database';
import DatabaseXoopsPathRedirect from '../database/DatabaseXoopsPathRedirect';
import About from '../custom/About';
import XoopsPathRedirect from './XoopsPathRedirect';

interface Props {
    lang: MultiLang;
}

const MainContent = (props: Props) => {
    const { lang } = props;
    return (
        <div className="mainContent">
            <Switch>
                <Route path="/database" render={() => <Database lang={lang} />} />
                {Config.D3FORUM_MODULES.map((name) =>
                    <Route key={name} path={'/' + name} render={() => <D3Forum lang={lang} name={name} />} />
                )}
                <Route exact path="/about" render={() => <About lang={lang} />} />
                <Route path="/modules/xoonips" render={() => <DatabaseXoopsPathRedirect lang={lang} />} />
                {Config.D3FORUM_MODULES.map((name) =>
                    <Route key={name} path={'/modules/' + name} render={() => <D3ForumXoopsPathRedirect lang={lang} name={name} />} />
                )}
                <Route component={XoopsPathRedirect} />
            </Switch>
        </div>
    );
}

export default MainContent;