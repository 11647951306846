import React from 'react';
import { MultiLang } from '../../config';
import NoticeSiteHasBeenArchived from '../../common/lib/NoticeSiteHasBeenArchived';

interface Props {
    lang: MultiLang;
}

const Annuonce = (props: Props) => {
    const { lang } = props;
    const announce = {
        'en':
            <p>This site is supported by a Grant-in-Aid for Scientific Research on Innovative Areas (Comprehensive Brain Science Network) from the Ministry of Education, Science, Sports and Culture of Japan.</p>,
        'ja':
            <p>
                当サイトは、新学術領域研究 <a href="https://www.hokatsu-nou.neuroinf.jp/" target="_blank" rel="noopener noreferrer">包括脳ネットワーク</a> がサポートしています。包括脳ネットワークのメンバーはユーザー登録後、このサイトに各種ファイルをアップロードし、公開・共有することができます。 当サイトは統合脳プラットフォームを引き継ぎ、包括脳ネットワークデータベース委員会により運営されています。
            </p>,
    };
    return (
        <>
            <NoticeSiteHasBeenArchived lang={lang} />
            {announce[lang]}
        </>
    );
}

export default Annuonce;