import React from 'react';
import { MultiLang } from '../config';
import IndexTree from '../database/blocks/IndexTree';
import Search from '../database/blocks/Search';
import Functions from '../functions';
import MainMenu from './blocks/MainMenu';

interface Props {
    lang: MultiLang;
}

const LeftColumn = (props: Props) => {
    const { lang } = props;
    const titles = {
        mainMenu: '[en]Main Menu[/en][ja]メインメニュー[/ja]',
        indexTree: '[en]Index Tree[/en][ja]インデックスツリー[/ja]',
        search: '[en]Search[/en][ja]検索[/ja]',
    };
    return (
        <div className="leftcolumn">
            <div className="block">
                <h2 className="blockTitle">{Functions.mlang(titles.mainMenu, lang)}</h2>
                <div className="blockContent">
                    <MainMenu lang={props.lang} />
                </div>
            </div>
            <div className="block">
                <h2 className="blockTitle">{Functions.mlang(titles.indexTree, lang)}</h2>
                <div className="blockContent">
                    <IndexTree lang={props.lang} />
                </div>
            </div>
            <div className="block">
                <h2 className="blockTitle">{Functions.mlang(titles.search, lang)}</h2>
                <div className="blockContent">
                    <Search lang={props.lang} />
                </div>
            </div>
        </div>
    );
}

export default LeftColumn;
