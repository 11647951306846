import React from 'react';
import { Link } from 'react-router-dom';
import Config, { MultiLang } from '../config';
import Functions from '../functions';
import mainMenus from './assets/main-menu.json';
import iconHome from './assets/theme/images/icon_home.gif';
import iconHomeHover from './assets/theme/images/icon_home_h.gif';
import iconInquiry from './assets/theme/images/icon_inquiry.gif';
import iconInquiryHover from './assets/theme/images/icon_inquiry_h.gif';
import logo from './assets/theme/images/logo.png';
import LangFlag from './lib/LangFlag';
import LinkImage from './lib/LinkImage';

interface Props {
    lang: MultiLang;
}

const Header = (props: Props) => {
    const title = Functions.mlang(Config.SITE_TITLE, props.lang);
    const titleStyle = props.lang === 'en' ? "titleEnglish" : "titleJapanese";
    return (
        <header className="header">
            <div className="clearfix">
                <div className="headerLeft">
                    <Link className="titleLink" to="/"><span className={titleStyle}>{title}</span></Link>
                </div>
                <div className="headerRight">
                    <div>
                        <img src={logo} alt="Comprehensive Brain Science Network" />
                    </div>
                    <div className="shortcut">
                        <LangFlag lang={props.lang} />
                        &nbsp;
                        <LinkImage url="/" image={iconHome} imageHover={iconHomeHover} title="Home" />
                        &nbsp;
                        <LinkImage url="/about" image={iconInquiry} imageHover={iconInquiryHover} title="Contact Us" />
                    </div>
                </div>
            </div>
            <div className="menuBar">
                <div className="menuLink">
                    {mainMenus.map((item, idx) => {
                        return <Link key={idx} to={item.link}>{Functions.mlang(item.title, props.lang)}</Link>;
                    })}
                </div>
            </div>
        </header>
    );
}

export default Header;
