import React from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import ForumJump from './lib/ForumJump';
import D3ForumUtils, { D3ForumPostSortOrder, D3ForumPostData } from './lib/D3ForumUtils';
import Post from './lib/Post';
import PostsTree from './lib/PostsTree';

interface Params {
	id?: string;
}

interface Props extends RouteComponentProps<Params> {
	lang: MultiLang;
	name: string;
}

const D3ForumPost = (props: Props) => {
	const { lang, name } = props;
	const params = props.match.params;
	if (typeof params.id === 'undefined') {
		return <PageNotFound lang={lang} />;
	}
	const postId = parseInt(params.id, 10);
	const post = D3ForumUtils.getPost(name, postId);
	if (post === null) {
		return <PageNotFound lang={lang} />;
	}
	const topic = D3ForumUtils.getTopic(name, post.topic_id);
	if (topic === null) {
		return <PageNotFound lang={lang} />;
	}
	const forum = D3ForumUtils.getForum(name, topic.forum_id);
	if (forum === null) {
		return <PageNotFound lang={lang} />;
	}
	const category = D3ForumUtils.getCategory(name, forum.cat_id);
	if (category === null) {
		return <PageNotFound lang={lang} />;
	}
	const posts = D3ForumUtils.getPosts(name, topic.topic_id, D3ForumPostSortOrder.TREE);
	const idx = posts.findIndex((post: D3ForumPostData) => {
		return post.post_id === postId;
	});
	const prevPostId = idx <= 0 ? 0 : posts[idx - 1].post_id;
	const nextPostId = (idx + 1) === posts.length ? 0 : posts[idx + 1].post_id;
	const title = Functions.mlang(post.subject, lang);
	return (
		<>
			<Helmet>
				<title>{title} - {D3ForumUtils.getTitle(name, lang)} - {Functions.siteTitle(lang)}</title>
			</Helmet>
			<div className="d3f_breadcrumbs">
				<Link to={D3ForumUtils.getIndexUrl(name)}>{Functions.mlang('[en]Top[/en][ja]トップ[/ja]', lang)}</Link>
				&nbsp;&gt;&nbsp;
                <Link to={D3ForumUtils.getCategoryUrl(name, category.cat_id)}>{Functions.mlang(category.cat_title, lang)}</Link>
				&nbsp;&gt;&nbsp;
                <Link to={D3ForumUtils.getForumUrl(name, forum.forum_id)}>{Functions.mlang(forum.forum_title, lang)}</Link>
				&nbsp;&gt;&nbsp;
                <Link to={D3ForumUtils.getTopicUrl(name, topic.topic_id, D3ForumPostSortOrder.TREE, null)}>{Functions.mlang(topic.topic_title, lang)}</Link>
			</div>
			<h1 className="d3f_title">{title}</h1>
			<PostsTree lang={lang} name={name} posts={posts} postId={postId} />
			<br />
			<p className="d3f_topicinfo">
				<Link to={D3ForumUtils.getTopicUrl(name, topic.topic_id, D3ForumPostSortOrder.TREE, null)}>{Functions.mlang('[en]List posts in the topic[/en][ja]このトピックの投稿一覧へ[/ja]', lang)}</Link>
			</p>
			<div className="d3f_wrap">
				<Post lang={lang} name={name} post={post} prevPostId={prevPostId} nextPostId={nextPostId} inTopic={false} />
			</div>
			<ForumJump lang={lang} name={name} forumId={forum.forum_id} />
		</>
	);
}

export default D3ForumPost;
