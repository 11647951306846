import React from 'react';
import { MultiLang } from '../../config';
import AdvancedSearchQuery from '../lib/AdvancedSearchQuery';
import { Item, ItemBinder, ItemBook, ItemConference, ItemData, ItemFiles, ItemMemo, ItemModel, ItemPaper, ItemPresentation, ItemSimulator, ItemStimulus, ItemTool, ItemUrl } from '../lib/ItemUtil';
import ItemTypeBinder from './binder';
import ItemTypeBook from './book';
import ItemTypeConference from './conference';
import ItemTypeData from './data';
import ItemTypeFiles from './files';
import ItemTypeMemo from './memo';
import ItemTypeModel from './model';
import ItemTypePaper from './paper';
import ItemTypePresentation from './presentation';
import ItemTypeSimulator from './simulator';
import ItemTypeStimulus from './stimulus';
import ItemTypeTool from './tool';
import ItemTypeUrl from './url';

interface TopProps {
    lang: MultiLang;
    type: string;
}
const Top = (props: TopProps) => {
    const { lang, type } = props;
    switch (type) {
        case 'xnpbinder':
            return <ItemTypeBinder.Top lang={lang} />;
        case 'xnpbook':
            return <ItemTypeBook.Top lang={lang} />;
        case 'xnpconference':
            return <ItemTypeConference.Top lang={lang} />;
        case 'xnpdata':
            return <ItemTypeData.Top lang={lang} />;
        case 'xnpfiles':
            return <ItemTypeFiles.Top lang={lang} />;
        case 'xnpmemo':
            return <ItemTypeMemo.Top lang={lang} />;
        case 'xnpmodel':
            return <ItemTypeModel.Top lang={lang} />;
        case 'xnppaper':
            return <ItemTypePaper.Top lang={lang} />;
        case 'xnppresentation':
            return <ItemTypePresentation.Top lang={lang} />;
        case 'xnpsimulator':
            return <ItemTypeSimulator.Top lang={lang} />;
        case 'xnpstimulus':
            return <ItemTypeStimulus.Top lang={lang} />;
        case 'xnptool':
            return <ItemTypeTool.Top lang={lang} />;
        case 'xnpurl':
            return <ItemTypeUrl.Top lang={lang} />;
        default:
            return null;
    }
}

interface ListProps {
    lang: MultiLang;
    item: Item;
}
const List = (props: ListProps) => {
    const { lang, item } = props;
    switch (item.item_type_name) {
        case 'xnpbinder':
            return <ItemTypeBinder.List lang={lang} item={item as ItemBinder} />;
        case 'xnpbook':
            return <ItemTypeBook.List lang={lang} item={item as ItemBook} />;
        case 'xnpconference':
            return <ItemTypeConference.List lang={lang} item={item as ItemConference} />;
        case 'xnpdata':
            return <ItemTypeData.List lang={lang} item={item as ItemData} />;
        case 'xnpfiles':
            return <ItemTypeFiles.List lang={lang} item={item as ItemFiles} />;
        case 'xnpmemo':
            return <ItemTypeMemo.List lang={lang} item={item as ItemMemo} />;
        case 'xnpmodel':
            return <ItemTypeModel.List lang={lang} item={item as ItemModel} />;
        case 'xnppaper':
            return <ItemTypePaper.List lang={lang} item={item as ItemPaper} />;
        case 'xnppresentation':
            return <ItemTypePresentation.List lang={lang} item={item as ItemPresentation} />;
        case 'xnpsimulator':
            return <ItemTypeSimulator.List lang={lang} item={item as ItemSimulator} />;
        case 'xnpstimulus':
            return <ItemTypeStimulus.List lang={lang} item={item as ItemStimulus} />;
        case 'xnptool':
            return <ItemTypeTool.List lang={lang} item={item as ItemTool} />;
        case 'xnpurl':
            return <ItemTypeUrl.List lang={lang} item={item as ItemUrl} />;
        default:
            return null;
    }
}

interface DetailProps {
    lang: MultiLang;
    item: Item;
}
const Detail = (props: DetailProps) => {
    const { lang, item } = props;
    switch (item.item_type_name) {
        case 'xnpbinder':
            return <ItemTypeBinder.Detail lang={lang} item={item as ItemBinder} />;
        case 'xnpbook':
            return <ItemTypeBook.Detail lang={lang} item={item as ItemBook} />;
        case 'xnpconference':
            return <ItemTypeConference.Detail lang={lang} item={item as ItemConference} />;
        case 'xnpdata':
            return <ItemTypeData.Detail lang={lang} item={item as ItemData} />;
        case 'xnpfiles':
            return <ItemTypeFiles.Detail lang={lang} item={item as ItemFiles} />;
        case 'xnpmemo':
            return <ItemTypeMemo.Detail lang={lang} item={item as ItemMemo} />;
        case 'xnpmodel':
            return <ItemTypeModel.Detail lang={lang} item={item as ItemModel} />;
        case 'xnppaper':
            return <ItemTypePaper.Detail lang={lang} item={item as ItemPaper} />;
        case 'xnppresentation':
            return <ItemTypePresentation.Detail lang={lang} item={item as ItemPresentation} />;
        case 'xnpsimulator':
            return <ItemTypeSimulator.Detail lang={lang} item={item as ItemSimulator} />;
        case 'xnpstimulus':
            return <ItemTypeStimulus.Detail lang={lang} item={item as ItemStimulus} />;
        case 'xnptool':
            return <ItemTypeTool.Detail lang={lang} item={item as ItemTool} />;
        case 'xnpurl':
            return <ItemTypeUrl.Detail lang={lang} item={item as ItemUrl} />;
        default:
            return null;
    }
}

interface AdvancedSearchProps {
    lang: MultiLang;
    type: string;
    query: AdvancedSearchQuery;
}
const AdvancedSearch = (props: AdvancedSearchProps) => {
    const { lang, type, query } = props;
    switch (type) {
        case 'xnpbinder':
            return <ItemTypeBinder.AdvancedSearch lang={lang} query={query} />;
        case 'xnpbook':
            return <ItemTypeBook.AdvancedSearch lang={lang} query={query} />;
        case 'xnpconference':
            return <ItemTypeConference.AdvancedSearch lang={lang} query={query} />;
        case 'xnpdata':
            return <ItemTypeData.AdvancedSearch lang={lang} query={query} />;
        case 'xnpfiles':
            return <ItemTypeFiles.AdvancedSearch lang={lang} query={query} />;
        case 'xnpmemo':
            return <ItemTypeMemo.AdvancedSearch lang={lang} query={query} />;
        case 'xnpmodel':
            return <ItemTypeModel.AdvancedSearch lang={lang} query={query} />;
        case 'xnppaper':
            return <ItemTypePaper.AdvancedSearch lang={lang} query={query} />;
        case 'xnppresentation':
            return <ItemTypePresentation.AdvancedSearch lang={lang} query={query} />;
        case 'xnpsimulator':
            return <ItemTypeSimulator.AdvancedSearch lang={lang} query={query} />;
        case 'xnpstimulus':
            return <ItemTypeStimulus.AdvancedSearch lang={lang} query={query} />;
        case 'xnptool':
            return <ItemTypeTool.AdvancedSearch lang={lang} query={query} />;
        case 'xnpurl':
            return <ItemTypeUrl.AdvancedSearch lang={lang} query={query} />;
        default:
            return null;
    }
}

const ItemType = {
    Top,
    List,
    Detail,
    AdvancedSearch
}

export default ItemType;