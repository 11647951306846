import { ItemPresentationSubTypes } from '../../lib/ItemUtil';
import AdvancedSearchBase, { AdvancedSearchBaseProps } from '../lib/AdvancedSearchBase';

class PresentationAdvancedSearch extends AdvancedSearchBase {
    constructor(props: AdvancedSearchBaseProps) {
        super(props);
        this.type = 'presentation';
        this.title = 'Presentation';
        const now = new Date();
        const year = String(now.getFullYear());
        const month = String(now.getMonth() + 1);
        const mday = String(now.getDate());
        this.state.values['title'] = '';
        this.state.values['keyword'] = '';
        this.state.values['description'] = '';
        this.state.values['doi'] = '';
        this.state.values['meeting_name'] = '';
        this.state.values['presentation_type'] = '';
        this.state.values['creator'] = '';
        this.state.values['publication_year'] = year;
        this.state.values['publication_month'] = month;
        this.state.values['publication_mday'] = mday;
        this.state.values['file.preview.caption'] = '';
        this.state.values['file.presentation_file.original_file_name'] = '';
        this.setIgnoreKey('publication_year');
        this.setIgnoreKey('publication_month');
        this.setIgnoreKey('publication_mday');
    }

    getRows() {
        const rows = [
            { label: '[en]Title[/en][ja]タイトル[/ja]', value: this.renderFieldInputText('title', 50) },
            { label: '[en]Free Keywords[/en][ja]フリーキーワード[/ja]', value: this.renderFieldInputText('keyword', 50) },
            { label: '[en]Description[/en][ja]概要[/ja]', value: this.renderFieldInputText('description', 50) },
            { label: 'ID', value: this.renderFieldInputText('doi', 50) },
            { label: '[en]Meeting Name[/en][ja]発表した会合の名称[/ja]', value: this.renderFieldInputText('meeting_name', 50) }, // CBSN extended
            { label: '[en]Presentation Type[/en][ja]ファイル形式[/ja]', value: this.renderFieldSelect('presentation_type', ItemPresentationSubTypes) },
            { label: '[en]Creator[/en][ja]作成者[/ja]', value: this.renderFieldInputText('creator', 50) },
            { label: '[en]Date[/en][ja]日付[/ja]', value: this.renderFieldDate('', 'publication_year', 'publication_month', 'publication_mday') },
            // { label: '[en]Caption[/en][ja]キャプション[/ja]', value: this.renderFieldInputText('file.preview.caption', 50) },
            { label: '[en]Caption of Preview[/en][ja]プレビューのキャプション[/ja]', value: this.renderFieldInputText('file.preview.caption', 50) }, // CBSN extended
            { label: '[en]Presentation File[/en][ja]発表資料[/ja]', value: this.renderFieldInputText('file.presentation_file.original_file_name', 50) },
        ];
        return rows;
    }
}

export default PresentationAdvancedSearch;