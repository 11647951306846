import moment from 'moment';
import React from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { HashLink } from 'react-router-hash-link';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import imageTopic01 from './assets/images/topic_01.gif';
import imageTopic10 from './assets/images/topic_10.gif';
import imageTopic11 from './assets/images/topic_11.gif';
import imageTopicInvisible from './assets/images/topic_invisible.gif';
import imageTopicStatusLocked1 from './assets/images/topic_status_locked1.gif';
import imageTopicStatusMarked1 from './assets/images/topic_status_marked1.gif';
import imageTopicStatusSolved0 from './assets/images/topic_status_solved0.gif';
import imageTopicSticky0 from './assets/images/topic_sticky0.gif';
import ForumJump from './lib/ForumJump';
import D3ForumUtils, { D3ForumPostData, D3ForumPostSortOrder, D3ForumTopicData } from './lib/D3ForumUtils';
import PostIcon from './lib/PostIcon';

interface Params {
	id?: string;
}
interface Props extends RouteComponentProps<Params> {
	lang: MultiLang;
	name: string;
}

const D3ForumForum = (props: Props) => {
	const { lang, name } = props;
	const params = props.match.params;
	if (typeof params.id === 'undefined') {
		return <PageNotFound lang={lang} />;
	}
	const forumId = parseInt(params.id, 10);
	const forum = D3ForumUtils.getForum(name, forumId);
	if (forum === null) {
		return <PageNotFound lang={lang} />;
	}
	const category = D3ForumUtils.getCategory(name, forum.cat_id);
	if (category === null) {
		return <PageNotFound lang={lang} />;
	}
	const topics = D3ForumUtils.getTopics(name, forum.forum_id);
	const title = Functions.mlang(forum.forum_title, lang);
	return (
		<>
			<Helmet>
				<title>{title} - {D3ForumUtils.getTitle(name, lang)} - {Functions.siteTitle(lang)}</title>
			</Helmet>
			<div className="d3f_breadcrumbs">
				<HashLink to={D3ForumUtils.getIndexUrl(name)}>{Functions.mlang('[en]Top[/en][ja]トップ[/ja]', lang)}</HashLink>
				&nbsp;&gt;&nbsp;
				<HashLink to={D3ForumUtils.getCategoryUrl(name, category.cat_id)}>{Functions.mlang(category.cat_title, lang)}</HashLink>
			</div>
			<h1 className="d3f_title">{title}</h1>
			<p className="d3f_welcome">{Functions.mlang(forum.forum_desc, lang)}</p>
			<table className="outer d3f_table">
				<thead>
					<tr className="head">
						<th colSpan={2}>{Functions.mlang(`[en]hits ${topics.length} items[/en][ja]${topics.length}件ヒットしました[/ja]`, lang)}</th>
						<th>{Functions.mlang('[en]Reply[/en][ja]返信[/ja]', lang)}</th>
						<th>{Functions.mlang('[en]First post[/en][ja]トピック開始[/ja]', lang)}</th>
						<th>{Functions.mlang('[en]Latest post[/en][ja]最新投稿[/ja]', lang)}</th>
					</tr>
				</thead>
				<tbody>
					{topics.map((topic: D3ForumTopicData, idx: number) => {
						const postStart = D3ForumUtils.getPost(name, topic.topic_first_post_id);
						const postLast = D3ForumUtils.getPost(name, topic.topic_last_post_id);
						if (postStart === null || postLast === null) {
							return null;
						}
						const postTitleStart = Functions.mlang(postStart.subject, lang);
						const imageTopic = topic.topic_invisible !== 0 ? imageTopicInvisible : topic.topic_sticky !== 0 ? imageTopicSticky0 : imageTopic10;
						const evenodd = idx % 2 === 0 ? 'even' : 'odd';
						const poster = (post: D3ForumPostData) => {
							const title = Functions.mlang(post.subject, lang);
							return (
								<>
									{moment(new Date(post.post_time * 1000)).format('YYYY/M/D')}<br />
									{post.uid_uname} <HashLink to={D3ForumUtils.getPostUrl(name, post.post_id)}><PostIcon lang={lang} post={post} title={title} /></HashLink>
								</>
							);
						}
						return (
							<tr key={topic.topic_id} className={evenodd}>
								<td className="d3f_mainicon">
									<img src={imageTopic} alt="" />
								</td>
								<td className="d3f_topictitle">
									{topic.topic_solved === 0 && <img src={imageTopicStatusSolved0} alt="" />}
									{topic.topic_locked !== 0 && <img src={imageTopicStatusLocked1} alt="" />}
									<HashLink to={D3ForumUtils.getTopicUrl(name, topic.topic_id, D3ForumPostSortOrder.TREE, postLast.post_id)}>{postTitleStart}</HashLink>
								</td>
								<td>{topic.topic_posts_count - 1}</td>
								<td className="d3f_posters">
									{poster(postStart)}
								</td>
								<td className="d3f_posters">
									{postStart.post_id !== postLast.post_id && poster(postLast)}
								</td>
							</tr>

						);
					})}
				</tbody>
			</table>
			<div className="d3f_iconexps clearfix">
				<ul className="d3f_iconexp">
					<li><img src={imageTopic11} alt="" width="18" height="18" /> = {Functions.mlang('[en]Topic with new posts[/en][ja]新しい投稿があります[/ja]', lang)}</li>
					<li><img src={imageTopic01} alt="" width="18" height="18" /> = {Functions.mlang('[en]Topic without new posts[/en][ja]新しい投稿はありません[/ja]', lang)}</li>
					<li><img src={imageTopicInvisible} alt="" width="18" height="18" /> = {Functions.mlang('[en]Invisible topic (Only admins and moderators can read this)[/en][ja]不可視トピック（管理者とモデレータ以外には見えません）[/ja]', lang)}</li>
					<li><img src={imageTopicSticky0} alt="" width="18" height="18" /> = {Functions.mlang('[en]Sticky topic[/en][ja]特別扱いされたトピック[/ja]', lang)}</li>
				</ul>
				<ul className="d3f_iconexp">
					<li><img src={imageTopicStatusLocked1} alt="" width="18" height="18" /> = {Functions.mlang('[en]Locked topic[/en][ja]ロックされたトピック[/ja]', lang)}</li>
					<li><img src={imageTopicStatusSolved0} alt="" width="18" height="18" /> = {Functions.mlang('[en]Unsolved topic[/en][ja]未解決トピック[/ja]', lang)}</li>
					<li><img src={imageTopicStatusMarked1} alt="" width="18" height="18" /> = {Functions.mlang('[en]Marked[/en][ja]未解決トピック[/ja]', lang)}</li>
				</ul>
			</div>
			<ForumJump lang={lang} name={name} forumId={forum.forum_id} />
		</>
	);
}

export default D3ForumForum;
