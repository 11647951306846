import React, { ReactElement } from 'react';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import { HashLink } from 'react-router-hash-link';
import { MultiLang } from '../../config';
import Functions from '../../functions';

interface Props {
    lang: MultiLang;
    text: string;
    dohtml?: boolean;
    dosmiley?: boolean;
    doxcode?: boolean;
    doimage?: boolean;
    dobr?: boolean;
}

const preConvertXCode = (text: string, doxcode: boolean): string => {
    if (doxcode) {
        return text.replace(/\[code\](.*)\[\/code\]/sg, (m0, m1) => {
            return '[code]' + Functions.base64Encode(m1) + '[/code]';
        });
    }
    return text;
}

const postConvertXCode = (text: string, doxcode: boolean, doimage: boolean): string => {
    if (doxcode) {
        return text.replace(/\[code\](.*)\[\/code\]/sg, (m0, m1) => {
            const text = convertXCode(Functions.htmlspecialchars(Functions.base64Decode(m1)), doimage);
            return '<div class="xoopsCode"><pre><code>' + text + '</code></pre></div>';
        });
    }
    return text;
}

const convertClickable = (text: string) => {
    text = text.replace(/(^|[^\]_a-zA-Z0-9-="'/]+)((?:https?|ftp)(?::\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+[a-zA-Z0-9=]))/g, (...matches) => {
        return matches[1] + '<a href="' + matches[2] + '" rel="external">' + matches[2] + '</a>';
    });
    text = text.replace(/(^|[^\]_a-zA-Z0-9-="'/:.]+)([a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+)/g, (...matches) => {
        return matches[1] + '<a href="mailto:' + matches[2] + '">' + matches[2] + '</a>';
    });
    return text;
}

const convertXCode = (text: string, doimage: boolean): string => {
    // TODO: implement
    return text;
}

const convertSmiley = (text: string) => {
    // TODO: implement
    return text;
}

const convertBr = (text: string): string => {
    return text.replace(/(\r?\n|\r)/g, '<br />');
}

interface TransformParsedNode {
    type: string;
    next: object | null;
    prev: object | null;
    parent: object | null;
    name: string;
    attribs: any;
    children: object[];
    data: string;
}

const cssConvert = (text: string): object => {
    const ret: any = {};
    text.split(';').forEach((line) => {
        const line_ = line.trim();
        if (line.length === 0) {
            return;
        }
        const kv = line_.split(':');
        const key = Functions.camelCase(kv[0].trim());
        const value = kv[1].trim();
        ret[key] = value;
    })
    return ret;
}

const transform = (node: object, idx: number): ReactElement | null | void => {
    const node_ = node as TransformParsedNode;
    if (node_.type === 'tag' && node_.name === 'a') {
        const url = node_.attribs && node_.attribs['href'];
        const isExternal = !url || /^(mailto|https?:?\/\/)/.test(url);
        if (!isExternal) {
            const style = (node_.attribs && node_.attribs['style']) || '';
            const title = (node_.attribs && node_.attribs['title']) || '';
            return <HashLink key={idx} to={url} style={cssConvert(style)} title={title}>{node_.children.map((value: object, index: number) => {
                return convertNodeToElement(value, index, transform);
            })}</HashLink>;
        }
    }
}

const XoopsCode = (props: Props) => {
    const { lang } = props;
    let text = props.text;
    const dohtml = !!props.dohtml;
    const dosmiley = !!props.dosmiley;
    const doxcode = !!props.doxcode;
    const doimage = !!props.doimage;
    const dobr = !!props.dobr;
    text = preConvertXCode(text, doxcode);
    if (!dohtml) {
        text = Functions.htmlspecialchars(text);
        text = convertClickable(text);
    }
    if (dosmiley) {
        text = convertSmiley(text);
    }
    if (doxcode) {
        text = convertXCode(text, doimage);
    }
    if (dobr) {
        text = convertBr(text);
    }
    text = postConvertXCode(text, doxcode, doimage);
    text = Functions.mlang(text, lang);
    return <div>{ReactHtmlParser(text, { transform })}</div>;
}

export default XoopsCode;