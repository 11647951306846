import React from 'react';
import { MultiLang } from '../config';
import Functions from '../functions';
import bannerCBSN from './assets/theme/images/banner_cbsn.gif';
import bannerIBR from './assets/theme/images/banner_ibr.gif';
import bannerJapanNode from './assets/theme/images/banner_incf-japan-node.gif';
import bannerCBS from './assets/theme/images/banner_riken-cbs.gif';
import bannerRIKEN from './assets/theme/images/banner_riken.gif';
import bannerXooNIps from './assets/theme/images/banner_xoonips.gif';

interface Props {
    lang: MultiLang;
}

const Footer = (props: Props) => {
    const links = [
        {
            title: '[en]INCF Japan Node[/en][ja]INCF 日本ノード[/ja]',
            image: bannerJapanNode,
            url: 'https://www.neuroinf.jp/',
        },
        {
            title: '[en]RIKEN[/en][ja]理化学研究所[/ja]',
            image: bannerRIKEN,
            url: 'http://www.riken.jp/',
        },
        {
            title: '[en]RIKEN Center for Brain Science[/en][ja]理化学研究所 脳神経科学研究センター[/ja]',
            image: bannerCBS,
            url: 'https://cbs.riken.jp/',
        },
        {
            title: '[en]The XooNIps Project[/en][ja]XooNIps 公式サイト[/ja]',
            image: bannerXooNIps,
            url: 'https://xoonips.osdn.jp/',
        },
        {
            title: '[en]Integrative Brain Research[/en][ja]統合脳プロジェクト[/ja]',
            image: bannerIBR,
            url: 'http://www.togo-nou.nips.ac.jp/',
        },
        {
            title: '[en]Comprehensive Brain Science Network[/en][ja]包括脳プロジェクト[/ja]',
            image: bannerCBSN,
            url: 'https://www.hokatsu-nou.neuroinf.jp/',
        },
    ];
    return (
        <footer className="footer">
            {links.map((link, idx) => {
                const title = Functions.mlang(link.title, props.lang);
                return <a key={idx} href={link.url} target="_blank" rel="noopener noreferrer"><img src={link.image} alt={title} title={title} /></a>
            })}
        </footer>
    );
}

export default Footer;
