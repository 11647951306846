const SITE_TITLE = '[en]CBSN platform[/en][ja]包括脳プラットフォーム[/ja]';
const SITE_SLOGAN = 'XooNIps for CBSN';
const GOOGLE_ANALYTICS_TRACKING_ID = 'UA-2780809-1';
const XOONIPS_ITEMTYPES = ['tool', 'paper', 'presentation', 'book', 'data', 'url', 'files'];
const D3FORUM_MODULES = ['forum'];

export type MultiLang = 'en' | 'ja';

const Config = {
    SITE_TITLE,
    SITE_SLOGAN,
    GOOGLE_ANALYTICS_TRACKING_ID,
    XOONIPS_ITEMTYPES,
    D3FORUM_MODULES,
}

export default Config;
